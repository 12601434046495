<template>
  <b-col cols="12" lg="12">
    <div
        class="absolute inset-0 z-30"
        @click="removeEvent"
        v-if="showAccountDrop || showArticleDrop"
    ></div>

    <SecondaryCard class="mt-2" :title="$t(locale.invoiceRow)">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <!-- {{local_invoice_rows}} -->
            <div class="flex flex-col w-full">
              <table class="table w-full z-50 relative mb-7">
                <thead class="">
                <tr>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.articleNumber)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.description)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.qty)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.unit)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.price)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.discount)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.total)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.costcenter)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.project)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.vat)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">{{
                          $t(locale.account)
                        }}</span>
                  </th>
                  <th class="text-left whitespace-nowrap p-2 md:p-0">
                    <span class="text-xs text-gray-400">Action</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <invoiceTableRowVue
                    :vat_included="vat_included"
                    :index="i"
                    @onRowChange="rowChange"
                    @removeRow="removeRow"
                    v-for="(item, i) in local_invoice_rows"
                    :data="item"
                    :key="i"
                    :booked="booked"
                    :cancelled="cancelled"
                    :fully_paid="fully_paid"
                />
                <!-- -->
                <tr class="tableRow">
                  <td>
                    <div>
                        <span class="relative flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.article_number"
                              @input="searchArticle"
                              @focus="showArticleDrop = true"
                              :disabled="booked"
                          />
                          <div
                              v-if="showArticleDrop"
                              class="absolute mt-4 z-50 bg-white rounded-md shadow custom-border c-p-3"
                          >
                            <ul>
                              <li
                                  class="cursor-pointer border-b"
                                  @click="changeArticle(item)"
                                  v-for="item in articleResults"
                                  :key="item.uuid"
                              >
                                {{
                                  item.article_number + `(${item.description})`
                                }}
                              </li>
                            </ul>
                          </div>
                        </span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.description"
                              :disabled="booked"
                          />
                        </span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.delivered_quantity"
                              @input="rowChange"
                              :disabled="booked"
                          />
                        </span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.unit"
                              :disabled="booked"
                          />
                          <!-- <td>
                          
                            <select
                              class="mid col-form"
                              v-model="row.unit"
                              :disabled="cancelled || booked"
                            >
                              <option value=""></option>
                              <option
                                :value="item.code"
                                v-for="item in units"
                                :key="item.id"
                              >
                                {{
                                  GET_LOCALE === "se"
                                    ? item.code
                                    : item.code_english
                                }}
                              </option>
                            </select>
                          </td> -->
                        </span>
                    </div>
                  </td>
                  <td>
                    <b-form-input
                        v-if="vat_included"
                        size="sm"
                        type="text"
                        v-model="row.price"
                        :disabled="cancelled || booked"
                        @change="rowChange"
                    />
                    <b-form-input
                        v-else
                        size="sm"
                        type="text"
                        v-model="row.price_excluding_vat"
                        :disabled="cancelled || booked"
                        @change="rowChange"
                    />
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.discount"
                              :disabled="booked"
                              @input="rowChange"
                          />
                        </span>
                    </div>
                  </td>
                  <td>
                    <b-form-input
                        v-if="vat_included"
                        size="sm"
                        type="text"
                        :value="row.total"
                        :disabled="cancelled || booked"
                    />
                    <b-form-input
                        v-else
                        size="sm"
                        type="text"
                        :value="row.total_excluding_vat"
                        :disabled="cancelled || booked"
                    />
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.cost_center"
                              :disabled="booked"
                          />
                        </span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.project"
                              :disabled="booked"
                          />
                        </span>
                    </div>
                  </td>
                  <td>
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.vat"
                              :disabled="booked"
                          />
                        </span>
                    </div>
                  </td>
                  <td width="100px">
                    <div>
                        <span class="flex flex-col">
                          <b-form-input
                              size="sm"
                              v-model="row.account_number"
                              @input="searchAccounts"
                              @focus="showAccountDrop = true"
                              :disabled="booked"
                          />
                          <div
                              v-if="showAccountDrop"
                              class="absolute mt-4 z-50 bg-white rounded-md shadow custom-border c-p-3"
                          >
                            <ul>
                              <li
                                  class="cursor-pointer border-b"
                                  @click="changeAccounts(item)"
                                  v-for="item in accountResults.slice(0, 7)"
                                  :key="item.uuid"
                              >
                                {{ item.number + `(${item.description})` }}
                              </li>
                            </ul>
                          </div>
                        </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="mt-2">
                <b-button
                    variant="secondaryColor"
                    v-text="'Lägg till faktuarrad'"
                    :disabled="cancelled || booked"
                    @click="addContractInvoiceRow"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </SecondaryCard>
  </b-col>
</template>

<script>
import {contractRow} from "./constants/contracts";
import invoiceTableRowVue from "./invoiceTableRow.vue";
import debounce from "lodash.debounce";
import vSelect from "vue-select";
import {registersMixin} from "@/mixins/registersMixin";
import bus from "@/bus";

export default {
  name: "InvoiceRow",
  mixins: [registersMixin],
  components: {
    vSelect,
    invoiceTableRowVue,
  },
  props: {
    invoice_rows: {
      type: Array,
      required: true,
    },
    invoice_country: {
      type: String,
      required: false,
    },
    booked: {
      type: Boolean,
      default: false,
      required: false,
    },
    cancelled: {
      type: Boolean,
      default: false,
      required: false,
    },
    vat_included: {
      type: Boolean,
      default: false,
      required: false,
    },
    fully_paid: {
      type: Boolean,
      default: false,
      required: false,
    },
    submitForm: {
      type: Boolean,
      default: false,
      required: false,
    },
    // locale : {
    //     type: Object,
    //     required: true,
    // }
  },
  data() {
    return {
      // item : data,
      accountResults: [],
      articleResults: [],
      local_invoice_rows: [],
      units: [],
      row: {...contractRow},
      locale: {
        invoiceRow: "invoicesEditCard.invoiceRows",
        articleNumber: "invoicesCard.table.itemId",
        description: "invoicesCard.table.description",
        qty: "invoicesCard.table.quantity",
        unit: "invoicesCard.table.unit",
        price: "invoicesCard.table.price", //
        discount: "golfRelatedCard.detail.profileTab.discount", // Discount %
        total: "HomeCard.Total",
        costcenter: "invoicesCard.table.cc",
        project: "invoicesCard.table.pr",
        vat: "invoicesCard.table.vat",
        account: "invoicesCard.account",
      },
      showArticleDrop: false,
      showAccountDrop: false,
    };
  },
  watch: {
    submitForm: function (n, o) {
      if (this.submitForm === false) return;
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (
          this.row.description === "" &&
          (this.row.price || this.row.delivered_quantity > 1)
      ) {
        this.$toast.warning(
            "Invoice row was not properly filled. New invoice row will not be saved!"
        );
      }
      // else if (!this.row.article_number && !this.row.account_number)
      if (this.row.description) {
        this.local_invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
      }
      this.setDefaultRows();
      this.$emit("updateContract");
    },
  },
  mounted() {
    // this.getUnit();
    const self = this;
    this.local_invoice_rows = this.invoice_rows;

    bus.$on("clear-row", (e) => {
      self.local_invoice_rows = [];
    });

    bus.$on("perform-delivery-qty", (_) => {
      self.runDeliveryQtyMultiplier();
    });
  },
  methods: {
    removePercentage(data) {
      return data !== undefined ? data.toString().replace("%", "") : data;
    },
    // getUnit() {
    //   const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
    //   this.$store
    //     .dispatch("clients/getCustomRequest", { URL })
    //     .then((res) => {
    //       this.units = res.data.results;
    //     })
    //     .catch((err) => {});
    // },
    addContractInvoiceRow() {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (this.row.account_number === "") {
        // return this.$services.helpers.notification('Account number is required', 'error', this)
        this.popupMsg(
            this.$t("Message.Failed"),
            "Select account number",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }
      if (this.row.article_number === "") {
        if (this.row.description === "" || this.row.price === "") {
          // return this.$services.helpers.notification('Price and Description is required for this invoice row', 'error', this)
          this.popupMsg(
              this.$t("Message.Failed"),
              "Description Article required",
              "AlertTriangleIcon",
              "danger"
          );
          return;
          // return this.$toast.warning(this.$t("description_article_required"));
        }
      }
      this.row.newRow = true;
      // this.local_invoice_rows.push(JSON.parse(JSON.stringify(this.row)));
      // this.local_invoice_rows.push(this.row);
      this.invoice_rows.push(this.row);
      this.$emit("calculateRow", {});
      this.setDefaultRows();

    },
    removeRow(item) {
      this.$emit("removeRow", item);
      this.$emit("calculateRow", {});
    },
    runDeliveryQtyMultiplier() {
      this.local_invoice_rows.forEach((element) => {
        element.delivered_quantity = element.delivered_quantity * -1;
      });
      this.$emit("calculateRow", {});
    },
    rowChange: debounce(function () {
      if (
          this.row.delivered_quantity !== "" &&
          this.row.delivered_quantity !== null &&
          this.row.price_excluding_vat !== "" &&
          this.row.price_excluding_vat !== null &&
          this.row.price !== "" &&
          this.row.price !== null
      ) {
        if (this.vat_included) {
          this.row.total = (
              parseInt(this.row.delivered_quantity) *
              parseFloat(this.row.price) *
              (1 - parseFloat(this.removePercentage(this.row.discount) / 100))
          ).toFixed(2);

          this.row.total_excluding_vat = (
              this.row.total /
              (1 + parseFloat(this.row.vat) / 100)
          ).toFixed(2);
          // price_per_unit is including VAT

          this.row.price_excluding_vat = (
              parseFloat(this.row.price) /
              parseFloat(1 + parseFloat(this.row.vat) / 100)
          ).toFixed(2);

          this.row.price = parseFloat(this.row.price).toFixed(2);
        } else {
          this.row.total_excluding_vat = (
              parseInt(this.row.delivered_quantity) *
              parseFloat(this.row.price_excluding_vat) *
              parseFloat(
                  1 - parseFloat(this.removePercentage(this.row.discount) / 100)
              )
          ).toFixed(2);

          this.row.total = (
              this.row.total_excluding_vat *
              (1 + parseFloat(this.row.vat) / 100)
          ).toFixed(2);

          this.row.price = (
              parseFloat(this.row.price_excluding_vat) *
              parseFloat(1 + this.row.vat / 100).toFixed(2)
          ).toFixed(2);

          this.row.price_excluding_vat = parseFloat(
              this.row.price_excluding_vat
          ).toFixed(2);
        }
        this.row.price_per_unit = this.row.price_excluding_vat;
      }
      this.$emit("calculateRow", {});
    }),
    setDefaultRows() {
      this.row = {...contractRow};
    },
    // selectItem (item) {
    //     this.showArticleDrop = false
    // },
    searchArticle(data) {
      if (data) {
        this.searchRemoteArticle(data, this);
      } else {
        this.showArticleDrop = false;
      }
    },
    // {
    //     if(search.length) {
    //         this.showArticleDrop = true
    //         loading(true);
    //         this.searchRemoteArticle(loading, search, this);
    //     }
    // },
    searchRemoteArticle: debounce(function (search, vm) {
      this.$useJwt
          .searchArticles(`?search=${escape(search)}&customize=no&active=1`)
          .then((res) => {
            this.articleResults = res.data.data.filter(
                (el) => el.active === true
            );
            if (res.data.data.length > 0) {
              this.showArticleDrop = true;
            }
          })
          .catch((err) => {
          });
    }, 350),
    changeArticle(item) {
      if (!item.active) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "This article is not active. Please select another article.",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }
      this.row.article_number = item.article_number;
      this.row.description = item.description;
      this.row.vat = item.vat;

      if (this.invoice_country == "SE") {
        this.row.account_number = item.sales_account;
      } else if (this.EUCountries.includes(this.invoice_country)) {
        this.row.account_number = item.euvat_account;
      } else {
        this.row.account_number = item.export_account;
      }
      this.row.price = item.sales_price;
      this.row.price_excluding_vat = item.sales_price / (1 + item.vat / 100);
      if (item.sales_price !== null && this.row.delivered_quantity !== null) {
        let total = this.row.price * this.row.delivered_quantity;
        let total_excluding_vat = total / (1 + item.vat / 100);

        if (!isNaN(total) && !isNaN(total_excluding_vat)) {
          this.row.total = total;
          this.row.total_excluding_vat = total_excluding_vat;
        } else {
          this.row.total = 0;
          this.row.total_excluding_vat = 0;
        }
      }
      this.row.cost_center = item.cost_center;
      this.row.project = item.project;
      this.row.unit = item.unit;
      this.showArticleDrop = false;
    },
    searchAccounts(search) {
      if (search) {
        // loading(true);
        this.searchRemoteAccounts(search, this);
      }
    },
    searchRemoteAccounts: debounce(function (search, vm) {
      this.$useJwt
          .getAllRegisters(`accounts?search=${escape(search)}`)
          .then((res) => {
            if (res.data.results.length > 0) {
              this.showAccountDrop = true;
            }
            this.accountResults = res.data.results;
            // loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changeAccounts(account) {
      this.row.account_number = account.number;
      this.showAccountDrop = false;
    },
    removeEvent() {
      this.showArticleDrop = false;
      this.showAccountDrop = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.table th {
  padding: 0.3rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 3px;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
