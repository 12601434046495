export const registersMixin = {
    data() {
        return {
            EUCountries : [
                "AT",
                "BE",
                "BG",
                "CY",
                "CZ",
                "DE",
                "DK",
                "EE",
                "ES",
                "FI",
                "FR",
                "GB",
                "GR",
                "HU",
                "HR",
                "IE",
                "IT",
                "LT",
                "LU",
                "LV",
                "MT",
                "NL",
                "PL",
                "PT",
                "RO",
                "SI",
                "SK",
            ]
        };
    },
    methods: {
        removePercentage(data) {
            return data !== undefined ? data.toString().replace('%', '') : data
        },
    },
};
